import { enUS, fr } from 'date-fns/locale'

import type { Locale } from 'date-fns'

export function selectDateFnsLocale(appLocale: string): Locale {
  switch (appLocale) {
    case 'en':
      return enUS
      break
    case 'fr':
      return fr
      break
    default:
      return enUS
      break
  }
}

export function selectDateFnsLocaleString(appLocale: string): string {
  switch (appLocale) {
    case 'en':
      return 'en-us'
    case 'fr':
      return 'fr'
    default:
      return 'en-us'
  }
}
